<template>
  <div id="mian">
    <div class="header">
      <span @click="toUrl('addcommercial_jbxx', false)">基本信息</span>
      <span id="fzcolor">结算信息</span>
      <span @click="toUrl('addcommercial_qdxx')">渠道信息</span>
      <span @click="toUrl('addcommercial_pzxx')">产品信息</span>
      <!-- <span @click="toUrl('addcommercial_dkxq')">终端信息</span> -->
    </div>
    <div class="desc">
      <h5 class="jsxx">结算信息</h5>
    </div>
    <div class="tab1">
      <table class="tab">
        <tr class="tab_title">
          <td style="width: 171px">
            <p>账户名</p>
          </td>
          <td style="width: 163px">
            <p>账号</p>
          </td>
          <td style="width: 187px">
            <p>开户行</p>
          </td>
          <td style="width: 95px">
            <p>联行号</p>
          </td>
          <td style="width: 269px">
            <p>账户类型</p>
          </td>
          <td style="width: 95px">
            <p>操作</p>
          </td>
        </tr>
        <tr v-for="(v, i) in tabData" :key="i">
          <td style="width: 171px">
            <p>{{ v.accountName }}</p>
          </td>
          <td style="width: 163px">
            <p>{{ v.accountNo }}</p>
          </td>
          <td style="width: 187px">
            <p>{{ v.bankName }}</p>
          </td>
          <td style="width: 95px">
            <p>{{ v.interBranchesNo }}</p>
          </td>
          <td style="width: 269px">
            <p>
              {{
                v.accountType == "COMPANY"
                  ? "企业"
                  : v.accountType == "PERSONAL"
                  ? "个人"
                  : ""
              }}
            </p>
          </td>
          <td style="width: 95px">
            <p>
              <i class="lianjie" @click="editTab(v, i)">编辑</i
              ><i class="lianjie" @click="delTab(i)">删除</i>
            </p>
          </td>
        </tr>
      </table>
    </div>
    <h6 class="xzjsxx" @click="addTab"><span>+</span>新增结算信息</h6>
    <div class="btn">
      <el-button @click="toUrl('addcommercial_jbxx', false)" class="back"
        >上一步</el-button
      >
      <el-button
        @click="toUrl('addcommercial_qdxx')"
        class="next"
        type="primary"
        >下一步</el-button
      >
    </div>
    <el-dialog title="结算账户" :visible.sync="overAccountShow" width="490px">
      <ul class="overAccount_ul">
        <li class="ul_left">
          <div class="overAccount_ul_title"><i>*</i>账户类型</div>
          <el-select
            v-model="formData.accountType"
            placeholder="选择账户类型"
            class="width100"
            tabindex="1"
          >
            <el-option label="企业" value="COMPANY"></el-option>
            <el-option label="个人" value="PERSONAL"></el-option>
          </el-select>
          <div class="overAccount_ul_title"><i>*</i>账户</div>
          <el-input
            v-model="formData.accountNo"
            maxlength="128"
            placeholder="输入账户"
            class="width100"
            tabindex="3"
          ></el-input>
          <div class="overAccount_ul_title">
            <i v-show="formData.accountType == 'COMPANY'">*</i>联行号
          </div>
          <el-input
            v-model="formData.interBranchesNo"
            maxlength="128"
            placeholder="输入联行号"
            class="width100"
            tabindex="5"
          ></el-input>
        </li>
        <li class="ul-right">
          <div class="overAccount_ul_title"><i>*</i>账户名</div>
          <el-input
            v-model="formData.accountName"
            maxlength="128"
            placeholder="输入账户名"
            class="width100"
            tabindex="2"
          ></el-input>
          <div class="overAccount_ul_title"><i>*</i>开户行</div>
          <el-input
            v-model="formData.bankName"
            maxlength="128"
            placeholder="输入开户行"
            class="width100"
            tabindex="4"
          ></el-input>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="overAccountShow = false"
          >取 消</el-button
        >
        <el-button class="addBt" :disabled="buttonFlag" @click="sureAdd"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      overAccountShow: false, //新增/修改表单展示
      formData: {
        accountNo: "", //true结算账户
        accountName: "", //true	结算账户名称
        bankName: "", //true	开户行
        interBranchesNo: "", //false	联行号（账户类型为企业时必填）
        accountType: "", //true	账户类型
      },
      tabData: [],
      editIndex: "",
      buttonFlag: false, //防止重复点击
    };
  },
  computed: {
    ...mapState({
      businessDetail: "user_businessDetail",
    }),
  },
  mounted() {
    if (this.businessDetail) {
      this.tabData = this.businessDetail.settlements
        ? this.businessDetail.settlements
        : [];
    }
  },
  methods: {
    ...mapMutations({
      setBusiness: "user_setBusiness",
    }),
    // 添加结算账户
    addTab() {
      this.formData = {
        accountNo: "",
        accountName: "",
        bankName: "",
        interBranchesNo: "",
        accountType: "",
      };
      this.editIndex = null;
      this.overAccountShow = true;
    },
    // 修改结算账户
    editTab(formData, index) {
      this.formData = JSON.parse(JSON.stringify(formData));
      this.editIndex = index;
      this.overAccountShow = true;
    },
    //删除结算账户
    delTab(index) {
      this.$confirm("此操作将删除该结算账户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.tabData.splice(index, 1);
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 确认添加结算账户
    sureAdd() {
      let data = JSON.parse(JSON.stringify(this.formData));
      if (!data.accountType) {
        this.$message.error("账户类型必选");
        return false;
      }
      if (!data.accountNo) {
        this.$message.error("结算账户必填");
        return false;
      }
      if (!data.accountName) {
        this.$message.error("结算账户名称必填");
        return false;
      }
      if (!data.bankName) {
        this.$message.error("开户行必填");
        return false;
      }
      if (data.accountType === "COMPANY") {
        if (!data.interBranchesNo) {
          this.$message.error("联行号必填");
          return false;
        }
      }
	     this.buttonFlag=true
      if (this.editIndex !== null) {
        this.tabData[this.editIndex] = data;
      } else {
        this.tabData.push(data);
      }
      this.$message.success("保存成功");
      this.overAccountShow = false;
      setTimeout(()=>{
        this.buttonFlag=false
      },2000)
    },
    // 页面跳转
    toUrl(name, bool = true) {
      if (bool) {
        if (this.tabData.length <= 0) {
          this.$message.error("至少添加一个结算账户");
          return false;
        }
      }
      this.$router.push({
        name: name,
      });
    },
  },
  beforeDestroy() {
    let data = {
      mchDetail: this.businessDetail ? this.businessDetail.mchDetail : null,
      settlements: this.tabData,
      mchChannels: this.businessDetail ? this.businessDetail.mchChannels : null,
    };
    this.setBusiness(data);
  },
};
</script>
<style scoped>
@import "../../../assets/css/desc.css";
.tab1{
  min-height: auto !important;
}
.next {
  width: 120px;
  height: 32px;
  padding: 0;
  background: rgba(72, 184, 182, 1);
  border-color: #5aafab;
  border-radius: 3px;
  line-height: 10px;
  margin-left: 10px;
  font-size: 12px;
}

.back {
  width: 120px;
  line-height: 10px;
  height: 32px;
  padding: 0;
  background: rgba(255, 255, 255, 1);
  border-color: #dce0e6;
  border: 1px solid rgba(220, 224, 230, 1);
  border-radius: 3px;
  color: #333333;
  font-size: 12px;
}

.btn {
  width: 100%;
  margin-top: 50px;
  text-align: center;
  height: 33px;
}
.title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 40px;
}

.addBt {
  width: 60px;
  height: 32px;
  background-color: #48b8b6;
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  color: #333333;
  font-size: 12px;
  padding: 0;
}

.overAccount_ul {
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
}

.overAccount_ul_title i {
  color: #48b8b6;
  padding-right: 3px;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.ul_left {
  float: left;
  width: 200px;
  margin-left: 24px;
}

.ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}
</style>
